const ajax = function ({ url, method, data, successCallback, errorCallback }) {
    $.ajax({
        contentType: "application/json",
        dataType: "json",
        data,
        url,
        method,
        error: function (error) {
            errorCallback(error);
        },
        success: function (res) {
            successCallback(res);
        },
    })
}

$(document).ready(function(){
    //pagination
    let button = $('.load-more');

    button.on('click', function (event) {
        let collectionId = $(event.target).attr('id');
        let gridContainer = $("#grid-items-" + collectionId);
        let children = $(gridContainer).children();
        let lastItemId = $(children).last().attr('id')

        let url = `/pagination-items?first_element_id=${parseInt(lastItemId) + 1}&collection=${collectionId}`;

        // console.log(url);

        ajax({
            url,
            method: 'GET',
            data: null,
            successCallback: function (res) {
                $('.load-more').remove();
                updateHtml(res, gridContainer, collectionId);
            },
            errorCallback: function (error) {
                $('.load-more').hide();
                console.log(error);
            }
        });
    });

    const updateHtml = function ({ cars }, gridItems, collectionId) {
        cars.forEach(car => {
            let gridContainer = gridItems;
            let item = createElement('div', ['item'], car.id);
            $(gridContainer).append(item);

            let image = createElement('div', ['image']);
            $(item).append(image);

            let a = createElement('a', [], null, {
                href: `${window.location.hostname}/cars/${car.slug}`
            });

            $(image).append(a);

            if (car.images.length) {
                let imgElement = createElement('img', ['max-w-full'], null,  {
                    src: `https://${window.location.hostname}/storage/images/${car.images[0].image}`
                });

                $(a).append(imgElement);
            }

            let label = createElement('label', ['label']);
            $(label).append(`Model premium <div class="question"><svg width="18" height="18" fill="#fff"><use xlink:href="#question"></use></svg></div>`);
            $(image).append(label);

            let answer = createElement('div', ['answer']);
            $(answer).append(`
            <div class="heading flex items-center justify-between">
                <h4 class="title-2 white">Selectie premium</h4>
                <div class="close-answer cursor-pointer">
                    <svg width="14" height="14" fill="#fff"><use xlink:href="#close-x"></use></svg>
                </div>
            </div>

            <p class="text white mt-3">24 luni lorem ipsum dolor sit amet pallestenques curabiturr</p>
        `);
            $(image).append(answer);

            let description = createElement('div', ['description']);
            $(item).append(description);

            let left = createElement('div', ['left']);
            $(description).append(left);

            let h3 = createElement('h3');
            $(h3).append(`${car.maker.name} ${car.model.name}`);
            $(left).append(h3);

            let price = createElement('div', ['price']);
            $(price).append(`Pret cu TVA <strong>${car.price}</strong></div>`)
            $(left).append(price);

            let span = createElement('span');
            $(span).append(`${car.currency === 'EURO' ? 'Euro' : 'RON'}</span>`);
            $(left).append(span);

            let right = createElement('div', ['right']);
            $(description).append(right);

            let ancor = createElement(
                'a',
                [
                    'main-btn',
                    'default'
                ],
                null,
                {
                    href: `${window.location.hostname}/cars/${car.slug}`
                }
            );

            $(right).append(ancor);
            $(ancor).append(`<span>Informatii +</span>`);
        });

        $(gridItems).parent().append(`<div class="text-center mt-5"><button class="load-more main-btn blue mx-auto" id="${collectionId}"><span id="${collectionId}">Load more</span></button></div>`)
    }

    const createElement = function (type, classes, id, attributes) {
        const element = document.createElement(type);
        $(element).addClass(classes);
        $(element).attr('id', id);

        if (attributes) {
            for (const [attribute, value] of Object.entries(attributes)) {
                $(element).attr(attribute, value);
            }
        }

        return element;
    }

    //slider-for
	$('.slider-for').slick({
		dots: false,
		arrows:false,
	});

	$('.controller-left').click(function(){
		$('.slider-for').slick('slickPrev');
	})

	$('.controller-right').click(function(){
		$('.slider-for').slick('slickNext');
	})

    setInterval(() => {
        $('.controller-right').click();
    }, 5000);

	setTimeout(function(){
		$('.hero-section').removeClass('disabled');
		$('.hero-loader').remove();
	}, 1000);

    let numItems = 24;

    $(".grid-items .item").slice(numItems).hide();

    $(window).scroll(() => {
        if ($(window).scrollTop() + $(window).height() >= $(document).height() - 20) {
            $("grid-items .item").slice(numItems, (numItems * 1)).fadeIn(420);
            numItems = numItems + numItems;
        }
    });

    $(window).scroll(function(){
        let bottom_pos = $('.header-calculator').position().top + $('.header-calculator').height();
        let top_pos = $('.header-calculator').position().top;

        if($(window).width() > 991) {
            if($(window).scrollTop() > bottom_pos) {
                $('#header-title').hide();
                $('.header-calculator').addClass('active');
                $('.header-calculator').removeClass('replaced');
            } else if($(window).scrollTop() == 0) {
                $('#header-title').show();
                $('.header-calculator').removeClass('active');
                $('.header-calculator').addClass('replaced');
            }
        }


        if($(window).width() < 991) {
            if($(window).scrollTop() > bottom_pos) {
                $('.search-car-activator').addClass('active');
            } else if($(window).scrollTop() == 0) {
                $('.search-car-activator').removeClass('active');
            }
        }
    });

    if($(window).width() < 991) {
        $('.search-car-activator').click(function(){
            let bottom_pos = $('.header-calculator').position().top + $('.header-calculator').height();
            let top_pos = $('.header-calculator').position().top;

            if($(window).scrollTop() > bottom_pos) {
                $('#header-title').hide();
                $('.header-calculator').toggleClass('active');
                $('.header-calculator').toggleClass('replaced');
            } else if($(window).scrollTop() == 0) {
                $('#header-title').hide();
                $('.header-calculator').removeClass('active');
                $('.header-calculator').removeClass('replaced');
            };
        });

        $(window).scroll(function(){
            let bottom_pos = $('.header-calculator').position().top + $('.filters-section').height();
            let top_pos = $('.header-calculator').position().top;

            if($(window).scrollTop() == 0) {
                $('.header-calculator').removeClass('active');
                $('.header-calculator').addClass('replaced');
            }
        });
    }
})
